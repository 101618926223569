import React from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { Form, PrimaryButton, FieldTextInput, FieldRadioButton } from '../../../components';

import css from './SignupForm.module.css';

import { useLocation } from 'react-router-dom';


const SignupFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        values,
        customerEmail,
        lastName,
        firstName,
        isCustomer,
        termsAndConditions,
        isInvitedUser,
        form,
        tabs,
        loginOrSignupError
      } = fieldRenderProps;
  
      // email
      const emailRequired = validators.required(
        intl.formatMessage({
          id: 'SignupForm.emailRequired',
        })
      );
      const emailValid = validators.emailFormatValid(
        intl.formatMessage({
          id: 'SignupForm.emailInvalid',
        })
      );

      // password
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      const [isPasswordMatched, setIsPasswordMatched] = React.useState(false);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress || (isInvitedUser && !isPasswordMatched);


      const handlePasswordChange = (e, confirmPassword) => {
        const { value } = e.target;
        form.change('password', value);
        setIsPasswordMatched(value === confirmPassword)
      }

      const handleConfirmPasswordChange = (e, password) => {
        const { value } = e.target;
        form.change('confirmPassword', value);

        setIsPasswordMatched(value === password)
      }

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {loginOrSignupError}
          {tabs}
          <div>
            <div className={css.radioBox}>
              {isCustomer || isInvitedUser ? null :
                <FieldRadioButton
                  label="Photographer"
                  className={css.radioSignup}
                  name="userRole"
                  value="photographer"
                  id="photographer"
                  type="radio"
                />}
              {isInvitedUser ? null : <FieldRadioButton
                label="Customer"
                className={css.radioSignup}
                name="userRole"
                value="customer"
                id="customer"
                type="radio"
              />}
            </div>
            {isInvitedUser ? null : <>
              <FieldTextInput
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={intl.formatMessage({
                  id: 'SignupForm.emailLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.emailPlaceholder',
                })}
                disabled={customerEmail ? true : false}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
              <div className={css.name}>
                <FieldTextInput
                  className={css.firstNameRoot}
                  type="text"
                  id={formId ? `${formId}.fname` : 'fname'}
                  name="fname"
                  autoComplete="given-name"
                  label={intl.formatMessage({
                    id: 'SignupForm.firstNameLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.firstNamePlaceholder',
                  })}
                  disabled={firstName ? true : false}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.firstNameRequired',
                    })
                  )}
                />
                <FieldTextInput
                  className={css.lastNameRoot}
                  type="text"
                  id={formId ? `${formId}.lname` : 'lname'}
                  name="lname"
                  autoComplete="family-name"
                  label={intl.formatMessage({
                    id: 'SignupForm.lastNameLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.lastNamePlaceholder',
                  })}
                  disabled={lastName ? true : false}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.lastNameRequired',
                    })
                  )}
                />
              </div></>}
            <FieldTextInput
              className={css.password}
              type="password"
              id={formId ? `${formId}.password` : 'password'}
              name="password"
              autoComplete="new-password"
              label={intl.formatMessage({
                id: 'SignupForm.passwordLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'SignupForm.passwordPlaceholder',
              })}
              validate={passwordValidators}
              onChange={(e) => handlePasswordChange(e, values.confirmPassword)}
            />

            {isInvitedUser ?
              <FieldTextInput
                className={css.password}
                type="password"
                id={formId ? `${formId}.confirmPassword` : 'confirmPassword'}
                name="confirmPassword"
                label={intl.formatMessage({
                  id: 'SignupForm.confirmPasswordLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.confirmPasswordPlaceholder',
                })}
                validate={passwordValidators}
                onChange={(e) => handleConfirmPasswordChange(e, values.password)}
              />
              : null
            }
          </div>

          {!isPasswordMatched && values?.password && values?.confirmPassword && <p>Passwords do not match</p>}
          {termsAndConditions}
          <div className={css.bottomWrapper}>
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              {isInvitedUser ? <FormattedMessage id="SignupForm.setPassword" /> : <FormattedMessage id="SignupForm.signUp" />}
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = { inProgress: false };

SignupFormComponent.propTypes = {
  inProgress: bool,
  termsAndConditions: node.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
